@import "src/scss/abstracts/variables";
@import "src/scss/abstracts/mixins";

.press-releases {
	padding: toRem(90);

	@include for-size(desktop-down) {
		padding: toRem(60);
	}
	@include for-size(tablet-down) {
		padding: toRem(30);
	}

	&__header {
		display: flex;
		margin-bottom: toRem(50);

		@include for-size(tablet-landscape-down) {
			flex-wrap: wrap;
		}

		&-text {
			flex-shrink: 0;

			@include for-size(tablet-down) {
				flex-shrink: 1;
			}

			h1 {
				font-size: toRem(60);
				margin: 0;
				line-height: 1.2;

				@include for-size(tablet-down) {
					font-size: toRem(36);
				}
			}
		}

		&-search {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			padding-top: toRem(10);
			padding-left: toRem(50);

			@include for-size(tablet-landscape-down) {
				padding-top: toRem(30);
				padding-left: toRem(0);
			}

			form {
				position: relative;
				display: block;
				width: 100%;
				height: toRem(50);
				max-width: toRem(490);

				@include for-size(tablet-landscape-down) {
					max-width: none;
				}
			}

			input {
				height: 100%;
				padding-right: toRem(60);
				font-size: toRem(18);

				// placeholder color
				&::placeholder {
					color: #6c6c6c;
					opacity: 1;
				}
			}

			button {
				all: unset;
				box-sizing: border-box;
				display: block;
				cursor: pointer;
				position: absolute;
				right: toRem(10);
				top: 50%;
				transform: translateY(-50%);
				padding: toRem(10);

				&:focus-visible {
					border: 1px solid $color-black;
				}
			}
		}
	}
}

.pr-featured {
	border: 1px solid red;
	padding: toRem(30);
	border: solid 1px #e0e0e0;
	border-radius: toRem(20);
	background: white;

	a {
		display: flex;
		gap: toRem(60);
		text-decoration: none;

		&:hover {
			text-decoration: none;
			color: $color-black;

			.pr-featured__content-title {
				text-decoration: underline;
				color: $color-link-hover;
			}
		}

		@include for-size(tablet-landscape-down) {
			flex-wrap: wrap;
			gap: toRem(30);
		}
	}

	&__image {
		width: 45%;
		aspect-ratio: 1.5;
		border-radius: toRem(10);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		flex-shrink: 0;

		@include for-size(desktop-large-down) {
			width: toRem(550);
		}
		@include for-size(desktop-down) {
			width: 50%;
		}
		@include for-size(tablet-landscape-down) {
			width: 100%;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: toRem(20) 0;

		@include for-size(tablet-landscape-down) {
			padding: 0;
		}

		&-sup {
			font-size: toRem(16);
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: toRem(15);
		}

		&-title {
			font-size: toRem(30);
			line-height: 1.2;
			font-weight: bold;
			margin-bottom: toRem(25);

			@include for-size(tablet-landscape-down) {
				max-width: toRem(700);
			}
			@include for-size(tablet-down) {
				font-size: toRem(24);
				margin-bottom: toRem(15);
			}
		}

		&-description {
			font-size: toRem(20);
			line-height: 1.4;
			margin-bottom: toRem(25);

			@include for-size(tablet-landscape-down) {
				max-width: toRem(700);
			}
		}

		&-date {
			font-size: toRem(14);
			font-weight: bold;
			text-transform: uppercase;
			color: #666666;
			letter-spacing: toRem(1.33);
		}
	}
}

.pr-articles {
	display: flex;
	flex-wrap: wrap;
	margin: toRem(50) 0;
	gap: 3%;

	@include for-size(desktop-down) {
		gap: 2%;
	}
	@media (max-width: 900px) {
		gap: 4%;
	}

	&--m-0 {
		margin: 0;
	}

	.pr-article {
		width: 31.333333%;
		margin-bottom: toRem(30);

		@media (max-width: 900px) {
			width: 48%;
		}
		@include for-size(phone-down) {
			width: 100%;
		}
	}
}

.pr-article-item {
	width: 100%;
	height: 100%;
	background: white;
	border: 1px solid transparent;
	box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.07);
	border: solid 1px #e0e0e0;
	border-radius: toRem(10);
	transition: all 500ms ease;

	&:hover,
	&:focus-visible {
		border: 1px solid $color-primary;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
	}

	a {
		display: flex;
		flex-direction: column;
		gap: toRem(20);
		text-decoration: none;
		padding: toRem(30);

		&:hover {
			text-decoration: none;
			color: $color-black;

			.hover-link {
				text-decoration: underline;
				color: $color-link-hover;
			}
		}
	}

	&__image {
		width: 100%;
		aspect-ratio: 1.5;
		border-radius: toRem(10);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	&__content {
		height: 100%;
		display: flex;
		flex-direction: column;

		&-title {
			font-size: toRem(22);
			line-height: 1.4;
			font-weight: bold;
			margin-bottom: toRem(20);

			@include for-size(tablet-landscape-down) {
				font-size: toRem(20);
			}
		}

		&-date {
			margin-top: auto;
			font-size: toRem(14);
			font-weight: bold;
			text-transform: uppercase;
			color: #666666;
			letter-spacing: toRem(1.33);
		}
	}
}
